
#countdown {
   // @countdown-color: contrast(@countdown-bg, black, white, 43%);
   // @countdown-bg2: darken(@countdown-bg, 8%);
   // background:         linear-gradient(@countdown-bg, @countdown-bg2);
   // background:      -o-linear-gradient(@countdown-bg, @countdown-bg2);
   // background:    -moz-linear-gradient(@countdown-bg, @countdown-bg2);
   // background: -webkit-linear-gradient(@countdown-bg, @countdown-bg2);
   background: url(../img/countdown.png);
   display: inline-block;
   height: 310px;
   width: 300px;
   //border-radius: 50%;
   position: fixed;
   margin-top: -150px;
   top: 50%;
   right:10px;
   text-align: center;
   font-weight: 300;
   //box-shadow: 0 0 10px -4px black;
   z-index: 9999;
   padding: 5px;
   border-radius: 3px;

// HOURS MINUTES SECONDS CONTAINER
  & > .others {
     display: inline-block;
     position: relative;
     top: 145px;
     padding: 2px;
     margin: 0;
     border-radius: 3px;
     font-size: 20px;

      //TIME TITLES
     .small-text {
       font-size: 13px;
       padding-top: 0px;

     }
 }

// HOURS MINUTES SECONDS VALUE
   & .others > span {
     padding: 10px;
     border-radius: 3px;
     background: rgba(255 , 255, 255, 0.4);
     display: inline-block;
     color: black;
     width: 50px;
     box-shadow: inset 0 0 6px -3px black;
     font-weight: 600;
}

// DAYS CONTAINER
   & > .main {

      // TIME TITLE
      .small-text {
        font-size: 13px;
        padding-top: 5px;
        position: absolute;
        top: 165px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
      }
   }
// DAYS VALUE
    & .main > span {
      background: rgba(255, 255, 255, 0.4);
      color: black;
      font-weight: 400;
      border-radius: 3px;
      padding:0px 10px;
      position: absolute;
      left: 50%;
      margin-left: -45px;
      top:100px;
      font-size: 40px;
      width: 90px;
      box-shadow: inset 0 0 6px -3px black;


   }

// TEXT
   p {
      font-size: 13px;
      margin: 0;
      position: relative;
      top: 50px;
      width: 150px;
      margin: 0 auto;
	  line-height: 1.55;
   }

// MOBILE
  @media (max-width: 1200px) {
      line-height: 0;

      //height: 150px;
      //width: 150px;
      width: 100%;
      text-align: left;
      height: 30px;
      background: none;
      background-color: white;
      top: auto;
      bottom: 0px;
      margin-top: 0;
      right: 0;
      left: 0;
      border-radius: 0;
      border-top: 2px solid @brand-primary;
      padding: 5px 10px 5px 10px;
      box-shadow: 0 0 10px -3px black;


      & > .main {
         width: 29%;
         //bottom: 50px;
         top: auto;
         position: relative;

         display: inline-block;
         //margin: 0;
         //padding: 0px;

         .small-text {
           text-align: right;
           right: 0;
            //bottom: 50px;
            //top: auto;
            top: -5px;
            position: absolute;
            display: inline-block;
            padding-top: 0;
            //top: 120px;
         }

      }
      & .main > span {
        background: none;
        box-shadow: none;
        left: 0;
         position: absolute;
         text-align: center;
         //padding: 5px;
         margin: 0;
         margin-left: 0;
         //top: 85px;
         //top: auto;
         top: -5px;
         font-size: 14px;
         width: auto;

      }
      p {
         //top: 15px;
         top: auto;
         bottom: 0;
         width: 70%;
         //padding-top: 5px;
         display: inline-block;
      }

      .others {
         display: none;
      }
  }

    @media (max-width: 400px) {
      p,  & .main > span,.small-text {
        font-size: 12px !important;
      }

      .small-text {
        margin-left: 0;
      }

        & > .main {
          width: 25%;
        }
    }
}
